import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Hero from "../components/hero/hero-right"
import Layout from "../components/layouts/courseLandingLayout"
import Navbar from "../components/navbars/courseLandingNavbar"
import zoom from "../assets/images/zoom.png"
import Button from "../components/buttons/linkButton"
import CheckoutButton from "../components/buttons/checkoutButton"
import BrandBox from "../components/brandBox/brandBoxWidget"
import ReactGA from "react-ga"
import styled from "styled-components"
import { Row, Container, Col } from "react-bootstrap"
import ApplyBox from "../components/applyBox"
import courseData from "../utils/course-data"
import moment from "moment"
import Capacity from "../components/capacity"
import NewsletterForm from "../components/forms/newsletterForm"
import PSModal from "../components/modal"

const NextStepsSection = styled.section`
  background: var(--black);
  margin-top: 50px;
  padding-top: 30px;

  p,
  h1,
  h3 {
    color: #fff;
  }

  .step-box {
    padding: 15px 0 15px 0;

    p {
      padding-top: 10px;
      font-size: 16px;
    }
  }
`

const PayDepositSection = styled.section`
  background: var(--yellow);

  padding-top: 30px;
  padding-bottom: 40px;

  .brandBoxTitle {
    padding-bottom: 0;
  }

  .brandBoxSubTitle {
    text-align: left;
    padding-left: 15px;
    padding-bottom: 10px;
  }

  .depositDeadline {
    padding-bottom: 0;
  }

  .depositExplanation {
    font-style: italic;
    opacity: 0.8;
  }
`

const ProgramCol = styled(Col)`
  padding-bottom: 50px;
`
const ProgramCard = styled.div`
  p {
    font-size: 14px;
    text-align: left;
    padding-left: 15px;
  }

  h3 {
    text-align: left;
    padding: 15px;
  }
`

const NewsletterDiv = styled.div`
  margin-top: 100px;
  background-color: var(--blue);
  padding-top: 120px;
  height: 100%;
  font-family: sans-serif;
  min-height: 800px;

  h1,
  p {
    color: var(--white);
  }

  p {
    margin-top: 20px;
    margin-bottom: 40px;
  }

  .cta-hold {
    margin-right: 20px;
  }

  @media (max-width: 768px) {
    padding: 2em;
  }
`

const MaxWidthContainer = styled(Container)`
  max-width: 1000px;
`

const CenterButton = styled.div`
  display: block;
  margin: 0 auto;
  width: 200px;
`

const CapacityDiv = styled.div`
  background-color: #fff;
  padding: 20px;
  box-shadow: -20px 20px var(--black);
  border: 5px solid var(--black);
`

const Deposit = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  const [showDepo, setShowDepo] = useState(false)

  const handleCloseDepo = () => setShowDepo(false)
  const handleShowDepo = e => setShowDepo(true)

  const handleCTAClick = e => {
    console.log(e)
  }

  const fireGTMEvent = () => {
    ReactGA.event({
      category: "CTA - Button",
      action: "Click",
      label: "deposit",
    })
  }

  const renderNextDepositDeadline = course => {
    return courseData.returnNextDepositDate(course.deposit_deadlines)
  }

  const renderFirstDepositDeadline = () => {
    const startDateObject = courseData.returnByStartDateObject(
      "upcomingCourses",
      courseData.nextStartDate("upcomingCourses").format("YYYY-MM-DD")
    )

    return moment(startDateObject.deposit_deadlines[0].date).format(
      "MMMM D, YYYY"
    )
  }

  const renderFirstDepositAmount = () => {
    const startDateObject = courseData.returnByStartDateObject(
      "upcomingCourses",
      courseData.nextStartDate("upcomingCourses").format("YYYY-MM-DD")
    )

    return startDateObject.deposit_deadlines[0].discount
  }

  const renderNextApplicationDeadline = () => {
    const startDateObject = courseData.returnByStartDateObject(
      "upcomingCourses",
      courseData.nextStartDate("upcomingCourses").format("YYYY-MM-DD")
    )

    return moment(startDateObject.applicationDeadline).format("MMMM D, YYYY")
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Navbar location={location} />
      <NewsletterDiv>
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <h1>Grab your Ultimate JS Guide.</h1>
            <p>
              Grab your free Ultimate JS Guide which includes an e-book, a side
              project cheat sheet so you never get stuck with what to build and
              a challenge to test your JS skills as well as a couple bonuses 😎.
            </p>
            <p>
              Each week we'll deliver you actionable advice on how to become a
              better, more hire-able developer, even if it's not with us. I hate
              spam, and I know you do too. I promise to only send you high
              quality content.
            </p>
            <NewsletterForm />
          </Col>
        </Row>
      </NewsletterDiv>
    </Layout>
  )
}

export default Deposit

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
