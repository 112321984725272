import React from "react"
import { ProgressBar } from 'react-bootstrap';
import styled from "styled-components";
import moment from 'moment';

const CohortCapacity = styled(ProgressBar)`
  height: 30px;
  border-radius: 0;
  margin-bottom: 20px;

  .progress-bar {
    background-color: var(--orange);
  }
`

const CapacityParent = styled.div`
  .capacity-box {
    p {
      margin-bottom: 0px;
      font-size: 16px;
      text-align: center;
      padding-bottom: 10px;
    }
  }
`

const Capacity = ({classToAdd, course, page}) => {
  const renderCapacityTitle = () => {
    if (page === 'course' || 'index') {
      return `${moment(course.startDate).format('MMMM DD')
      } ${course.program}`;   
    }
  };

  return (
    <CapacityParent className={(classToAdd ? classToAdd : null)}>
      <div className='capacity-box'>
        <p><em>{renderCapacityTitle()}</em></p>
        <CohortCapacity now={course.capacity} />
      </div>
    </CapacityParent>
  )
}

export default Capacity;
