import React, { useState } from "react"
import styled from "styled-components"
import { Formik, Form as FormikForm, Field } from "formik"
import Button from "../buttons/button"
import fetch from "isomorphic-unfetch"
import * as Yup from "yup"
import ReactGA from "react-ga"
import Cookies from "js-cookie"

const FormContainer = styled.div`
  h1 {
    text-align: center;
    padding: 0 0 10px 0;
  }
  p {
    font-size: 14px;
  }
`

const ErrorMsg = styled.div`
  color: red;
`

const FieldContainer = styled.div`
  width: 100%;
  font-family: "roboto-mono";
  margin: 15px 0;
  input {
    width: 100%;
    height: 55px;
  }
`

const fireGTMEvent = () => {
  ReactGA.event({
    category: "CTA - Button",
    action: "Click",
    label: "waitlist-submit",
  })
}

const SignupSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
})

export default function SyllabusForm() {
  const [success, setSuccess] = useState(false)

  const submitForm = ({ email }) => {
    const postUrl = `https://api.convertkit.com/v3/forms/6076309/subscribe`

    fireGTMEvent()

    const body = {
      email,
      api_key: process.env.GATSBY_CONVERTKIT_API_KEY,
    }

    fetch(postUrl, {
      method: "post",
      body: JSON.stringify(body),
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json, application/xml, text/plain, text/html, *.*",
      }),
    })
      .then(res => res.json())
      .then(() => {
        setSuccess(true)
      })
      .catch(err => {
        console.log(err)
        setSuccess(false)
      })
  }

  const showSuccess = () => {
    if (success) {
      return (
        <>
          <h2 style={{ color: "#fff" }}>Thanks!</h2>
          <p>
            We just sent you an email to confirm! Make sure to check your SPAM
            and move my email address (brian@parsity.io) from "promotions" (if
            you use Gmail)!
          </p>
        </>
      )
    } else {
      return (
        <FormContainer>
          <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={SignupSchema}
            onSubmit={values => {
              submitForm(values)
            }}
          >
            {({ errors, touched }) => (
              <FormikForm>
                <FieldContainer>
                  <Field
                    placeholder="Email Address"
                    name="email"
                    type="email"
                  />
                  <ErrorMsg>
                    {errors.email && touched.email ? (
                      <div>{errors.email}</div>
                    ) : null}
                  </ErrorMsg>
                </FieldContainer>

                <div className="text-left">
                  <Button text="Send me the guide">Send me the guide</Button>
                </div>
              </FormikForm>
            )}
          </Formik>
        </FormContainer>
      )
    }
  }

  return showSuccess()
}
